import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";
import {
    RichNavigationLinkTypes,
    RichNavigationLinkCollection as RichNavigationLinkCollectionValue,
    RichNavigationBannerContentAssembly,
} from "@reactivated";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import { RichNavigationGeneralLink } from "../RichNavigationGeneralLink";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import { RichNavigationButtonLink } from "../RichNavigationButtonLink";
import { RichNavigationOfferLink } from "../RichNavigationOfferLink";
import { RichNavigationProductLink } from "../RichNavigationProductLink";
import { RichNavigationDynamicProductLink } from "../RichNavigationDynamicProductLink";
import { RichNavigationImageLink } from "../RichNavigationImageLink";
import { RichNavigationBannerLink } from "../RichNavigationBannerLink";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { useCurrentBreakpoint } from "../../../utils/hooks";
import { RichNavigationVariantOptions } from "../constants";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { bannerContentAssemblyComponents } from "../RichNavigation";

import styles from "./index.module.scss";
import sharedStyles from "../shared.module.scss";
import variantStyles from "./variants.module.scss";

const richNavigationLinkTypeComponents: StreamFieldBlockMap<RichNavigationLinkTypes> =
    {
        rich_nav_general_link: RichNavigationGeneralLink,
        rich_nav_offer_link: RichNavigationOfferLink,
        rich_nav_product_link: RichNavigationProductLink,
        rich_nav_dynamic_product_link: RichNavigationDynamicProductLink,
        rich_nav_image_link: RichNavigationImageLink,
        rich_nav_banner_link: RichNavigationBannerLink,
        rich_nav_button_link: RichNavigationButtonLink,
    };

export interface RichNavigationLinkCollectionContentProps {
    value: RichNavigationLinkCollectionValue;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export interface WrapperProps {
    collection: RichNavigationLinkCollectionContentProps;
    banner_content: RichNavigationBannerContentAssembly | null;
}

const MenuTrigger = (props: { children: React.ReactNode }) => {
    const viewport = useCurrentBreakpoint();
    if (viewport.belowMobile) {
        return <>{props.children}</>;
    }
    return (
        <NavigationMenu.Trigger
            className={concatClassNames([
                sharedStyles.hoverEffect,
                styles.trigger,
            ])}
        >
            {props.children}
        </NavigationMenu.Trigger>
    );
};

const MenuContent = (props: { children: React.ReactNode }) => {
    const viewport = useCurrentBreakpoint();
    if (viewport.belowMobile) {
        return <>{props.children}</>;
    }
    return (
        <NavigationMenu.Content className={styles.content}>
            {props.children}
        </NavigationMenu.Content>
    );
};

export const RichNavigationLinkCollection = (props: WrapperProps) => {
    return (
        <section
            className={concatClassNames([
                "rich-nav-link-collection",
                props.collection.value.variant ??
                    RichNavigationVariantOptions.DEFAULT,
            ])}
        >
            <MenuTrigger>
                <h2 className={styles.link}>{props.collection.value.name}</h2>
            </MenuTrigger>
            <MenuContent>
                <RichNavigationLinkCollectionContent {...props.collection} />
                {props.banner_content && (
                    <StreamField
                        components={bannerContentAssemblyComponents}
                        value={props.banner_content}
                    />
                )}
            </MenuContent>
        </section>
    );
};

const getVariantClass = (style: string | null) => {
    switch (style) {
        case "rich-navigation--link-collection--promo-image": {
            return variantStyles.promoImageWrapper;
        }
        default:
            return undefined;
    }
};

export const RichNavigationLinkCollectionContent = (
    props: RichNavigationLinkCollectionContentProps,
) => {
    if (!props.value.links) {
        return null;
    }
    return (
        <div className={getVariantClass(props.value.variant ?? "")}>
            <div className={variantStyles.linkWrapper}>
                {props.value.links.map((link, i) => (
                    <StreamField
                        key={i}
                        tagName={React.Fragment}
                        components={richNavigationLinkTypeComponents}
                        value={link.value}
                    />
                ))}
            </div>
            {props.value.promo_image &&
                props.value.variant ===
                    RichNavigationVariantOptions.PROMO_IMAGE && (
                    <ImageChooserBlock
                        attrs={{ className: variantStyles.promoImage }}
                        value={props.value.promo_image}
                    />
                )}
        </div>
    );
};
