import React, { useEffect, useState } from "react";
import { jt } from "ttag";
import { _RichNavigation__DynamicProductLink as Value } from "@reactivated";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { getProductPrice } from "../../../api/products";
import { money } from "../../../utils/format";
import { isoProductAPIURL } from "../../../models/nominals";
import { IAPIPrice } from "../../../models/prices.interfaces";
import Flair from "../../../common/Flair";

import styles from "./index.module.scss";

export const DynamicPrice = (props: IAPIPrice["unit"]) => {
    if (props.cosmetic_incl_tax !== props.incl_tax) {
        const strikeThruPrice = <s>{money(props.incl_tax)}</s>;
        return (
            <p className={styles.priceWrapper}>
                {jt`Starting at ${strikeThruPrice}`}
                <span className={styles.price}>
                    {money(props.cosmetic_incl_tax)}
                </span>
            </p>
        );
    }
    return <p className={styles.price}>{money(props.incl_tax)}</p>;
};

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const RichNavigationDynamicProductLink = (props: Props) => {
    if (
        props.value.promo_period !== null &&
        props.value.promo_period !== undefined &&
        !props.value.promo_period.is_promo_active
    ) {
        return null;
    }

    const [price, setPrice] = useState<IAPIPrice | null>(null);
    useEffect(() => {
        const apiURL = isoProductAPIURL.wrap(
            `/api/products/${props.value.product}/`,
        );
        let ignore = false;
        const fetchPrice = async () => {
            const resp = await getProductPrice(apiURL, 1);
            if (!ignore) {
                setPrice(resp);
            }
        };

        fetchPrice();
        return () => {
            ignore = true;
        };
    }, [props.value.product]);

    return (
        <a className={styles.root} href={props.value.destination}>
            {props.value.image && (
                <ImageChooserBlock
                    imageAttrs={{ className: styles.img }}
                    value={props.value.image}
                />
            )}
            <div className={styles.container}>
                {props.value.title && (
                    <RichTextBlock
                        className={styles.richText}
                        value={props.value.title}
                    />
                )}
                {props.value.flair && <Flair flair={props.value.flair} />}
                <RichTextBlock
                    className={styles.copy}
                    value={props.value.text}
                />
                <div>{price && <DynamicPrice {...price.unit} />}</div>
                {props.value.secondary_flair && (
                    <Flair flair={props.value.secondary_flair} />
                )}
            </div>
        </a>
    );
};
