import React from "react";
import { _RichNavigation__BannerLink as Value } from "@reactivated";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import Flair from "../../../common/Flair";
import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const RichNavigationBannerLink = (props: Props) => {
    const isActive =
        !props.value.promo_period || props.value.promo_period.is_promo_active;
    if (!isActive) {
        return null;
    }
    return (
        <a href={props.value.destination} className={styles.bannerLink}>
            <RichTextBlock value={props.value.text} />
            {props.value.flair && <Flair flair={props.value.flair} />}
        </a>
    );
};
